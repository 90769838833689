.k-test {
	position: absolute;
	left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.k-test[data-open="false"],
.k-test [data-open="false"] {
  opacity: 0;
  pointer-events: none;
}

.k-neutral {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(./bg.png);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.k-test video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
}